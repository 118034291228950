<template>
    <div>
        <el-form label-position="right" :rules="rules" ref='formInfo' inline label-width="80px" :model="formInfo"
            :size='tabsize'>
            <el-row>
                <el-col :span='12'>
                    <el-form-item label="上级菜单">
                        <el-input v-model="pName" @focus=" innerVisible = true " suffix-icon="el-icon-edit"
                            :disabled="typeof this.formInfo.id !== 'undefined' "></el-input>
                        <el-dialog width="30%" title="选择上级菜单" :visible.sync="innerVisible" append-to-body>
                            <el-form>
                                <el-tree :data="menu" node-key="id" ref="tree" highlight-current show-checkbox
                                    check-strictly :default-expanded-keys="defaultShowNodes" :props="defaultProps"
                                    :default-checked-keys='thispid' @check-change="handleNodeClick">
                                </el-tree>
                                <el-form-item class="btn">
                                    <el-button :size='tabsize' type="primary" @click="choosed">提交</el-button>
                                </el-form-item>
                            </el-form>
                        </el-dialog>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label="菜单类型">
                        <el-select v-model="value" :disabled="typeof this.formInfo.id !== 'undefined' "
                            placeholder="选择菜单类型" @change='typechange'>
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span='12'>
                    <el-form-item label="菜单名称" prop='cname'>
                        <el-input v-model="formInfo.cname" maxlength='10'></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label="菜单顺序" prop='inx'>
                        <el-input-number v-model="formInfo.inx" controls-position="right" :min="0" :max="99">
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span='12'>
                    <el-form-item label="路由地址">
                        <el-input v-model="formInfo.url"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="图标" class='icons'>
                        <!-- <el-input v-model="formInfo.url"></el-input> -->
                        <icon-picker v-model="formInfo.icon"></icon-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <p class="subbtn">
                <el-button type="primary" :size='tabsize' @click="submitForm('formInfo');">提交
                </el-button>
            </p>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                innerVisible: false,
                defaultProps: {
                    children: 'children',
                    label: 'cname'
                },
                options: [{
                    value: 4,
                    label: '系统'
                }, {
                    value: 1,
                    label: '目录'
                }, {
                    value: 2,
                    label: '功能'
                }, {
                    value: 3,
                    label: '按钮'
                }],
                value: '',
                pName: '',
                rules: {
                    cname: [{
                        required: true,
                        message: '请输入菜单名称',
                        trigger: 'blur'
                    }],
                    inx: [{
                        required: true,
                        message: '请输入顺序',
                        trigger: 'blur'
                    }],
                },
                defaultShowNodes: [0],
                treeinput: false,
                thispid: []
            }
        },
        mounted() {
            this.thispid = []
            if (this.formInfo.id) {
                this.thispid.push(this.formInfo.pid)
            } else {
                this.thispid.push(0)
            }
            if (this.thispid == 0) {
                this.pName = '根节点'
            }
        },
        props: ['formInfo'],
        computed: {
            // 获取选择上级菜单树形控件数据
            menu() {
                let data = JSON.parse(localStorage.getItem('menuArr'))
                let arr = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].mtype !== 3) {
                        arr.push(data[i])
                    }
                }
                arr = this.$table2Tree(arr)

                return [{
                    pid: -1,
                    id: 0,
                    cname: '根节点',
                    children: arr
                }]
            },
            // 监听尺寸按钮变化 改变表格大小
            tabsize() {
                return this.$store.state.tabsize
            }
        },
        watch: {
            // 监听表单value变化
            formInfo: {
                immediate: true,
                handler() {

                    let data = JSON.parse(localStorage.getItem('menuArr'))
                    for (let i = 0; i < data.length; i++) {
                        if (this.formInfo.pid === data[i].id) {
                            this.pName = data[i].cname
                        }
                    }
                    this.value = this.formInfo.mtype
                    if (this.value == 4) {
                        this.pName = '根节点'
                    }

                },
                deep: true
            },
            value() {
                if (this.formInfo.mtype == 4) {
                    this.pName = '根节点'
                    this.formInfo.pid = 0
                    this.thispid = [0]
                }
            }
        },
        methods: {
            // 提交表单
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$parent.$parent.loading = true
                        this.$parent.$parent.formShow = false
                        let url
                        let str
                        if (this.formInfo.id) {
                            url = this.$api.menu.edit
                            str = '修改'
                        } else {
                            url = this.$api.menu.add
                            str = '添加'
                        }
                        url(this.formInfo).then(res => {
                            console.log(res);
                            this.$parent.$parent.loading = false
                            if (res.status == 1) {
                                this.$notify.success({
                                    title: 'Info',
                                    message: str + '成功',
                                    showClose: false,
                                    duration: 2000,
                                });
                                this.$parent.$parent.updateList()
                            } else {
                                this.$notify.error({
                                    title: 'Info',
                                    message: res.msg,
                                    showClose: false,
                                    duration: 2000,
                                });
                            }
                        }).catch((error) => {
                            console.log(error);
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })
            },
            // 确认选择上级菜单 
            choosed() {
                this.pName = this.$refs.tree.getCheckedNodes()[0].cname
                this.formInfo.pid = this.$refs.tree.getCheckedKeys()[0]
                this.innerVisible = false
            },
            // 选择菜单类型
            typechange(value) {
                this.formInfo.mtype = value
            },
            // 上级菜单控件单选
            handleNodeClick(data, checked, node) {
                if (checked === true) {
                    this.checkedId = data.id;
                    this.$refs.tree.setCheckedKeys([data.id]);
                } else {
                    if (this.checkedId == data.id) {
                        this.$refs.tree.setCheckedKeys([data.id]);
                    }
                }
            },

        },
    }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }

    .el-input__inner,
    .el-form-item {
        width: 100%;
    }

    .el-form/deep/.el-form-item__content {
        width: calc(100% - 92px);
    }

    .subbtn {
        margin-top: 20px;
        text-align: center;
    }

    .el-tree {
        height: 400px;
        overflow-y: auto;
    }

    .el-dialog__body {
        position: relative;
    }


    .btn {
        /* position: absolute;
        bottom: 0; */
        margin-top: 30px;
        margin-bottom: 0 !important;
    }

    .icons {
        width: calc(100% - 10px);
    }

    .icons/deep/.el-form-item__content {
        width: calc(100% - 80px);
    }

    .el-input-number {
        width: 190px;
    }
</style>