<template>
    <div class="box" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
        <!-- 表头 -->
        <div class="teb_header">
            <el-button icon="el-icon-plus" :size='tabsize' @click='addTo'>新增</el-button>
            <!-- <el-button icon="el-icon-delete" :size='tabsize'>删除</el-button> -->
            <el-button @click='expandTree' icon="el-icon-arrow-down" :size='tabsize'>全部展开
            </el-button>
            <el-button @click='resetTree' icon="el-icon-arrow-up" :size='tabsize'>全部折叠
            </el-button>
        </div>
        <!-- 表格主体 -->
        <el-table :data="tableData" v-loading="tabloading" element-loading-text="拼命加载中" ref="theTable"
            element-loading-spinner="el-icon-loading" style="width: 100%;margin-bottom: 20px; height:100%;"
            :size='tabsize' row-key="id" border>
            <el-table-column prop="cname" label="模块名称">
            </el-table-column>
            <el-table-column prop="mtype" :formatter='formatterColumn' label="模块类型">
            </el-table-column>
            <el-table-column prop="url" label="路由">
            </el-table-column>
            <el-table-column prop="icon" label="图标">
            </el-table-column>
            <el-table-column prop="inx" label="顺序">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button :size='tabsize' icon='el-icon-edit' @click="handleEdit(scope.$index, scope.row,$event)">
                        编辑
                    </el-button>
                    <el-button :size='tabsize' type="danger" icon='el-icon-delete'
                        @click="handleDelete(scope.$index, scope.row);dialogVisible = true">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 新增/编辑 表单 -->
        <el-dialog :title="titleTxt" :close-on-click-modal="false" top='25vh' width='600px' :visible.sync="formShow"
            v-if="formShow">
            <forms :formInfo='formInfo'></forms>
        </el-dialog>
        <!-- 删除确认弹框 -->
        <el-dialog title="提示" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%">
            <span>确定要删除吗</span>
            <span slot="footer" class="dialog-footer">
                <el-button :size='tabsize' @click="dialogVisible = false">取 消</el-button>
                <el-button :size='tabsize' type="primary" @click="dialogVisible = false; del()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import forms from './form.vue'
    export default {
        components: {
            forms
        },
        data() {
            return {
                tableData: [],
                input: '',
                formShow: false,
                titleTxt: '新增',
                formInfo: {
                    pid: 0
                },
                dialogVisible: false,
                thisid: '',
                loading: true,
                tabloading: false,
            }
        },
        methods: {
            // 获取菜单数据 并储存
            updateList() {
                this.$api.menu.updateList().then(res => {
                    console.log("获取到的menu=>",res)
                    let datas = res.obj
                    localStorage.setItem('menuArr', JSON.stringify(datas))
                    this.tableData = this.$table2Tree(datas)
                    this.loading = false
                }).catch(error => {
                    console.log(error);
                })
            },
            // 表格菜单类型格式化
            formatterColumn(row, column, cellValue, index) {
                if (row.mtype == 4) {
                    return '系统'
                } else if (row.mtype == 1) {
                    return '目录'
                } else if (row.mtype == 2) {
                    return '功能'
                } else if (row.mtype == 3) {
                    return '按钮'
                }
            },
            // 编辑按钮
            handleEdit(index, row) {
                this.thisid = row.id
                this.loading = true
                this.$api.menu.get({
                        id: this.thisid
                    })
                    .then(res => {
                        this.formInfo = res.obj
                        this.formShow = true
                        this.titleTxt = '编辑'
                        this.loading = false
                    }).catch((error) => {
                        console.log(error);
                        this.loading = false
                    })

            },
            // 点击删除按钮 获取当前ID
            handleDelete(index, row) {
                this.thisid = row.id
            },
            // 删除确认
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            // 删除
            del() {
                this.tabloading = true
                this.$api.menu.del({
                    ids: this.thisid
                }).then(res => {
                    if (res.status == 1) {
                        this.tabloading = false
                        this.$notify.success({
                            title: 'success',
                            message: '删除成功',
                            showClose: false,
                            duration: 2000,
                        });
                        this.updateList()
                    } else {
                        this.$notify.error({
                            title: 'error',
                            message: res.msg,
                            showClose: false,
                            duration: 2000,
                        });
                        this.tabloading = false
                    }
                }).catch((error) => {
                    console.log(error);
                    this.tabloading = false
                })
            },
            // 新增按钮 
            addTo() {
                this.formShow = true
                this.titleTxt = '新增'
                this.formInfo = {
                    pid: 0,
                    url: '#',
                    inx: 0,
                    mtype: 2
                }
            },
            toggleRowExpansion(isExpansion) {
                this.toggleRowExpansion_forAll(this.tableData, isExpansion);
            },
            toggleRowExpansion_forAll(data, isExpansion) {
                data.forEach(item => {
                    this.$refs.dataTreeList.toggleRowExpansion(item, isExpansion);
                    if (item.children != undefined && item.children != null) {
                        this.toggleRowExpansion_forAll(item.children, isExpansion);
                    }
                })
            },
            //列表展开和收起
            forArr(arr, isExpand) {
                arr.forEach(i => {
                    this.$refs.theTable.toggleRowExpansion(i, isExpand);
                    if (i.children) {
                        this.forArr(i.children, isExpand);
                    }
                });
            },
            expandTree() {
                this.forArr(this.tableData, true);
                // console.log(this.$refs.theTable);
            },
            resetTree() {
                this.forArr(this.tableData, false);
            }
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            },
        },
        created() {
            this.updateList()
        },
    }
</script>

<style scoped>
    .box {
        width: 100%;
        height: 100%;
    }

    .box/deep/ .el-table th.gutter {
        display: table-cell !important;
    }

    .teb_header {
        /* height: 30px; */
        line-height: 30px;
        padding: 5px 0 5px 10px;
    }

    .teb_header .el-input {
        width: auto;
        margin-left: 20px;
    }

    .el-pagination {
        position: absolute;
        left: 50px;
        bottom: 10px;
    }

    .el-dialog__wrapper/deep/.el-dialog__header {
        text-align: center;
    }

    /deep/.el-table__body-wrapper {
        height: 85%;
        overflow-y: auto;
    }
</style>